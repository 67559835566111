import { Input } from "@/Components/ui/input";
import { navigate } from "@/Hooks/Functions";
import { Icon } from "@iconify/react";
import { useState } from "react";
import { toast } from "sonner";


export default function CodeFinder() {
    const [code, setCode] = useState("");

    const onSearch = () => {
        if (code.trim() === "") {
            toast.error("Please enter a code");
            return;
        }
        navigate(route('code-finder', { code: code }));
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            onSearch()
        };
    }
    return <div className="flex flex-row max-w-[300px] items-center pl-4 border-2 rounded-full text-white gap-x-4 bg-primary">
        <span className="text-sm whitespace-nowrap">Code Finder</span>
        <div className="relative flex overflow-hidden rounded-full p-[2px] items-center">
            <Input className="rounded-full" placeholder="Search ..." value={code} onChange={(e) => setCode(e.target.value)} onKeyDown={onEnter} />
            <Icon icon="mdi:search" className="absolute text-primary text-2xl right-2" onClick={() => onSearch()} />
        </div>
    </div>
}
